import './LanguageConfig.css';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { listenToStorageChanges } from '../../../../../../services/StorageService';
import { getLanguages, getSelectedLanguage, setSelectedLanguage } from '../../../../../../services/LanguagesService';
import { SessionStorageDataEvents } from '../../../../../../enums/StorageData';

function LanguageConfig() {
  const { t } = useTranslation('common');

  const languages = getLanguages();
  const [selectedLang, setSelectedLang] = useState<string>(getSelectedLanguage());

  useEffect(() => {
    listenToStorageChanges(SessionStorageDataEvents.language, () => setSelectedLang(getSelectedLanguage()));
  }, []);

  return (
    <div className='language-config-component'>
      <div className='medium-font-size'>{t('language_config_section')}</div>
      <div className='flags-container'>
        {languages.map(lang => (
          <img key={`flag-${lang.name}`} className={`flag${(selectedLang === lang.name) ? ' selected' : ''}`} src={lang.imageUrl} alt={`${lang.name}-flag`} onClick={() => setSelectedLanguage(lang.name)} />
        ))}
      </div>
    </div>
  );
}

export default LanguageConfig;
