import './RouletteConfig.css';
import { useState } from 'react';
import { Accordion, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { WheelOption, WheelSubOption } from '../../../../../../databases/wheelOptions';
import { getSessionWheelOptions, setSessionWheelOptions } from '../../../../../../services/WheelOptionsService';
import CustomButton from '../../../../../shared/custom-button/CustomButton';
import CustomIcon from '../../../../../shared/custom-icon/CustomIcon';

function RouletteConfig() {
  const { t } = useTranslation('common');

  const [parsedWheelOptions, setParsedWheelOptions] = useState<WheelOption[]>();
  const [wheelOptionsToAdd, setWheelOptionsToAdd] = useState<WheelOption[]>([]);
  const [showNewWheelOptionForm, setShowNewWheelOptionForm] = useState<boolean>(false);
  const [editingWheelOptionData, setEditingWheelOptionData] = useState<{ wheelOptionIndex: number; wheelOptionsArray: 'parsedWheelOptions' | 'wheelOptionsToAdd'; }>();
  const [newWheelOptionName, setNewWheelOptionName] = useState<string>('');
  const [newWheelOptionSubOptions, setNewWheelOptionSubOptions] = useState<string[]>([]);
  const [openedAccordionIndex, setOpenedAccordionIndex] = useState<string>('');

  const updateDefaultHide = (event: any, wheelOption: (WheelOption | WheelSubOption), mainWheelOption?: WheelOption): void => {
    event.stopPropagation();

    const defaultHideValue = !event.target.checked;

    wheelOption.defaultHide = defaultHideValue;

    if (!!mainWheelOption) {
      // Check all enabled or disabled
      if (!!mainWheelOption.subOptions?.find(subOption => !subOption.defaultHide)) {
        mainWheelOption.defaultHide = false;
      } else if (mainWheelOption.subOptions?.every(subOption => subOption.defaultHide)) {
        mainWheelOption.defaultHide = true;
      }
    } else if ((wheelOption as WheelOption).subOptions?.length) {
      (wheelOption as WheelOption).subOptions?.map(subOption => subOption.defaultHide = defaultHideValue);
    }

    setParsedWheelOptions([...parsedWheelOptions as WheelOption[]]);
  };

  const updateOpenedAccordion = (event: any, accordionItemIndex: number): void => {
    // If header, header title or checkbox header has been clicked
    if (event.target.name?.indexOf('header-checkbox') >= 0 || event.target.type === 'button' || event.target.className.indexOf('header-title') >= 0) {
      const currentAccordionItemIndex = accordionItemIndex.toString();
      const accordionIndexToOpen = (event.target.tagName === 'INPUT')
        ? (event.target.checked) ? currentAccordionItemIndex : ''
        : (currentAccordionItemIndex === openedAccordionIndex) ? '' : currentAccordionItemIndex

      setOpenedAccordionIndex(accordionIndexToOpen);
    }
  };

  const saveCustomRouletteOption = (): void => {
    if (newWheelOptionName) {
      const filteredWheelOptionSubOptions = newWheelOptionSubOptions?.reduce((acc, subOptionName) => (!!subOptionName) ? [...acc, { name: subOptionName }] : acc, [] as WheelSubOption[]);
      const wheelOptionSubOptions = (filteredWheelOptionSubOptions.length) ? filteredWheelOptionSubOptions : undefined;

      if (editingWheelOptionData) {
        if (editingWheelOptionData.wheelOptionsArray === 'parsedWheelOptions') {
          setParsedWheelOptions(
            parsedWheelOptions?.map((wheelOption, index) => (index === editingWheelOptionData.wheelOptionIndex)
              ? { ...wheelOption, name: newWheelOptionName, subOptions: wheelOptionSubOptions }
              : wheelOption
            )
          );
        } else if (editingWheelOptionData.wheelOptionsArray === 'wheelOptionsToAdd') {
          setWheelOptionsToAdd(
            wheelOptionsToAdd?.map((wheelOption, index) => (index === editingWheelOptionData.wheelOptionIndex)
              ? { ...wheelOption, name: newWheelOptionName, subOptions: wheelOptionSubOptions }
              : wheelOption
            )
          )
        }
      } else {
        setWheelOptionsToAdd([
          ...wheelOptionsToAdd,
          {
            name: newWheelOptionName,
            isCustom: true,
            defaultHide: false,
            subOptions: wheelOptionSubOptions
          }
        ]);
      }

      resetAddCustomRouletteOptionForm();
    }
  };

  const addCustomRouletteOptionSubOption = (): void => {
    if (newWheelOptionSubOptions.every(subOption => !!subOption)) {
      setNewWheelOptionSubOptions([...newWheelOptionSubOptions, '']);
    }
  };

  const updateCustomRouletteOptionSubOptionName = (subOptionName: string, index: number): void => {
    const clonnedSubOptions = [...newWheelOptionSubOptions];
    clonnedSubOptions[index] = subOptionName;
    setNewWheelOptionSubOptions(clonnedSubOptions);
  };

  const resetAddCustomRouletteOptionForm = (): void => {
    setNewWheelOptionName('');
    setNewWheelOptionSubOptions([]);
    setShowNewWheelOptionForm(false);
    setEditingWheelOptionData(undefined);
  };

  const startEditingCustomRouletteOption = (wheelOption: WheelOption, wheelOptionIndex: number): void => {
    setEditingWheelOptionData(getEditingWheelOptionData(wheelOption, wheelOptionIndex));
    setNewWheelOptionName(wheelOption.name);
    setNewWheelOptionSubOptions((wheelOption.subOptions || []).map(subOption => subOption.name));
    setShowNewWheelOptionForm(true);
  };

  const deleteCustomRouletteOption = (wheelOption: WheelOption, wheelOptionIndex: number): void => {
    const _editingWheelOptionData = getEditingWheelOptionData(wheelOption, wheelOptionIndex);

    if (_editingWheelOptionData.wheelOptionsArray === 'wheelOptionsToAdd') {
      setWheelOptionsToAdd(wheelOptionsToAdd?.filter(_wheelOption => _wheelOption.name !== wheelOption.name) as WheelOption[]);
    } else if (_editingWheelOptionData.wheelOptionsArray === 'parsedWheelOptions') {
      setParsedWheelOptions(parsedWheelOptions?.filter(_wheelOption => !_wheelOption.isCustom || _wheelOption.name !== wheelOption.name));
    }

    // If the wheel option to remove is being edited
    if (editingWheelOptionData?.wheelOptionsArray === _editingWheelOptionData.wheelOptionsArray && editingWheelOptionData.wheelOptionIndex === _editingWheelOptionData.wheelOptionIndex) {
      resetAddCustomRouletteOptionForm();
    }
  };

  const getEditingWheelOptionData = (wheelOption: WheelOption, wheelOptionIndex: number): { wheelOptionIndex: number; wheelOptionsArray: "parsedWheelOptions" | "wheelOptionsToAdd"; } => {
    const isAWheelOptionToAdd = wheelOptionsToAdd.find(_wheelOption => _wheelOption.isCustom && _wheelOption.name === wheelOption.name);
    if (isAWheelOptionToAdd) {
      return { wheelOptionIndex: (parsedWheelOptions || []).length - wheelOptionIndex, wheelOptionsArray: 'wheelOptionsToAdd' };
    } else {
      return { wheelOptionIndex, wheelOptionsArray: 'parsedWheelOptions' };
    }
  };

  const deleteCustomRouletteOptionSubOption = (newWheelSubOptionName: string): void => {
    setNewWheelOptionSubOptions(newWheelOptionSubOptions.filter(wheelOptionSubOption => wheelOptionSubOption !== newWheelSubOptionName));
  };

  const resetWheelConfiguration = (): void => {
    setParsedWheelOptions(getSessionWheelOptions());
    setWheelOptionsToAdd(wheelOptionsToAdd.map(wheelOption => ({ ...wheelOption, defaultHide: false, subOptions: wheelOption.subOptions?.map(subOption => ({ ...subOption, defaultHide: false })) })));
  };

  const submitForm = (): void => {
    setSessionWheelOptions([
      ...parsedWheelOptions as WheelOption[],
      ...wheelOptionsToAdd
    ]);
  };

  if (!parsedWheelOptions) {
    resetWheelConfiguration();
  }

  return (
    <div className='roulette-config-component'>
      <div className='medium-font-size'>{t('roulette_config_section')}</div>
      <Form className='roulette-options-form' onSubmit={submitForm}>
        <Accordion activeKey={openedAccordionIndex}>
          {[...parsedWheelOptions || [], ...wheelOptionsToAdd]?.map((wheelOption, oIndex) => (
            <Accordion.Item eventKey={`${oIndex}`} key={`wheel-option-${oIndex}`} onClick={(e) => updateOpenedAccordion(e, oIndex)}>
              <Accordion.Header className={`${wheelOption.subOptions?.length ? '' : 'accordion-header-without-content'}`}>
                <div className='roulette-option'>
                  <Form.Check type='checkbox' id={`default-checkbox-${oIndex}`} checked={!wheelOption.defaultHide} name={`header-checkbox-${oIndex}`} onChange={(e) => updateDefaultHide(e, wheelOption)} />
                  <div className='header-title'>
                    <span className='medium-font-size'>{t(wheelOption.name)}</span>
                    {wheelOption.condition && (
                      <OverlayTrigger overlay={<Tooltip id={`wheelOption-${oIndex}-tooltip`}><div className='small-font-size'>{t(wheelOption.condition)}</div></Tooltip>}>
                        <div><CustomIcon type='warning' size='small' /></div>
                      </OverlayTrigger>
                    )}
                  </div>
                  {wheelOption.isCustom && (
                    <>
                      <CustomIcon type='edit' size='small' onClick={() => startEditingCustomRouletteOption(wheelOption, oIndex)} />
                      <CustomIcon type='delete' size='small' onClick={() => deleteCustomRouletteOption(wheelOption, oIndex)} />
                    </>
                  )}
                </div>
              </Accordion.Header>
              {wheelOption.subOptions?.length && (
                <Accordion.Body>
                  <div className='roulette-options'>
                    {wheelOption.subOptions?.map((subOption, sIndex) => (
                      <div className='roulette-option' key={`wheel-subOption-${sIndex}`}>
                        <Form.Check type='checkbox' id={`default-checkbox-${oIndex}-${sIndex}`} checked={!subOption.defaultHide} name={`body-checkbox-${oIndex}-${sIndex}`} onChange={(e) => updateDefaultHide(e, subOption, wheelOption)} />
                        <span className='medium-font-size'>{t(subOption.name)}</span>
                      </div>
                    ))}
                  </div>
                </Accordion.Body>
              )}
            </Accordion.Item>
          ))}
        </Accordion>

        <div className={`new-wheel-option-container${showNewWheelOptionForm ? ' opened' : ''}`}>
          {!showNewWheelOptionForm && (<>
            <div className='small-font-size'>{t('add_new_wheel_option_info')}</div>
            <CustomButton text='add_new_wheel_option' onClick={() => setShowNewWheelOptionForm(true)} />
          </>
          )}
          {showNewWheelOptionForm && (
            <>
              <div className='medium-font-size'>{t('add_new_wheel_option')}</div>
              {/* Option */}
              <Form.Control type='text' placeholder={t('new_wheel_option_name')} value={newWheelOptionName} onChange={(e) => setNewWheelOptionName(e.target.value)} />
              {/* SubOptions */}
              <div className='new-wheel-option-suboptions'>
                {newWheelOptionSubOptions.map((newWheelOptionSubOption, index) => (
                  <div key={`new-wheel-option-subOption-${index}`} className='new-wheel-option-subOption'>
                    <Form.Control type='text' placeholder={t('new_wheel_suboption_name')} value={newWheelOptionSubOption} onChange={(e) => updateCustomRouletteOptionSubOptionName(e.target.value, index)} />
                    <CustomIcon type='delete' size='small' onClick={() => deleteCustomRouletteOptionSubOption(newWheelOptionSubOption)} />
                  </div>
                ))}
              </div>
              <div className='buttons-container'>
                <CustomButton text='cancel' onClick={resetAddCustomRouletteOptionForm} />
                <CustomButton text='add_new_wheel_suboption' onClick={addCustomRouletteOptionSubOption} />
                <CustomButton text='save' onClick={saveCustomRouletteOption} />
              </div>
            </>
          )}
        </div>

        <div className='buttons-container'>
          <CustomButton text='discard_changes' onClick={resetWheelConfiguration} />
          <CustomButton text='save' type='submit' />
        </div>
      </Form>
    </div>
  );
}

export default RouletteConfig;
