import './Summary.css';
import EnemyMains from './enemy-mains/EnemyMains';
import PartnersMain from './partners-main/PartnersMain';
import PickEnemyTeamChampions from './pick-enemy-team-champions/PickEnemyTeamChampions';
import RandomChampion from './random-champion/RandomChampion';
import RandomInitial from './random-initial/RandomInitial';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { WheelFunctions } from '../../../enums/wheelFunctions';
import { hideWheelOption } from '../../../services/WheelOptionsService';
import { getSummaryContent, resetSummaryContent } from '../../../services/SummaryService';
import { listenToStorageChanges } from '../../../services/StorageService';
import { LocalStorageDataEvents } from '../../../enums/StorageData';
import CustomButton from '../../shared/custom-button/CustomButton';

export interface SummaryContent {
  title: string;
  subtitle?: string;
  description?: string;
  options?: string[];
  action?: WheelFunctions;
}

function Summary() {
  const { t } = useTranslation('common');

  const [_summaryContent, _setSummaryContent] = useState<SummaryContent | undefined>();

  const getActionContent = (action: WheelFunctions): JSX.Element => {
    switch (action) {
      case WheelFunctions.pickEnemyTeamChampions:
        return (<PickEnemyTeamChampions />);
      case WheelFunctions.randomChampion:
        return (<RandomChampion />);
      case WheelFunctions.partnersMain:
        return (<PartnersMain />);
      case WheelFunctions.enemyMains:
        return (<EnemyMains />);
      case WheelFunctions.randomInitial:
        return (<RandomInitial />);
    }
  };

  const removeOption = (): void => {
    const content = _summaryContent as SummaryContent;
    hideWheelOption(content.title, content.subtitle);
    resetSummary();
  };

  const resetSummary = (): void => {
    resetSummaryContent();
  };

  useEffect(() => {
    listenToStorageChanges(LocalStorageDataEvents.summary, () => _setSummaryContent(getSummaryContent()));
  }, []);

  return (
    <>
      {!!_summaryContent && (
        <div className='summary-component custom-border'>
          <>
            {!!_summaryContent?.title && <div className='title'>{t(_summaryContent.title)}</div>}
            {!!_summaryContent?.subtitle && <div className='subtitle medium-font-size'>{t(_summaryContent.subtitle)}</div>}
            {!!_summaryContent?.description && <div className='description medium-font-size'>{t(_summaryContent.description)}</div>}
            {!!_summaryContent?.action && (
              <div className='action-container'>
                {getActionContent(_summaryContent.action)}
              </div>
            )}
            {!!_summaryContent?.options && (
              <div className='options'>
                {_summaryContent.options.map((option, index) => <div className='option small-font-size' key={`option-${index}`}>{t(option)}</div>)}
              </div>
            )}

            <div className='buttons-container'>
              <CustomButton text='remove_option' onClick={removeOption} />
              <CustomButton text='continue' onClick={resetSummary} />
            </div>
          </>
        </div>
      )}
      {!_summaryContent && (
        <div className='default-message'>{t('instructions')}</div>
      )}
    </>
  );
}

export default Summary;
