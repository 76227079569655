import { getTeams, Player } from '../../../../services/TeamsService';
import './PartnersMain.css';
import { useTranslation } from 'react-i18next';

interface PlayerWithPartnersMain extends Player {
  partnersMain?: string;
}

function PartnersMain() {
  const { t } = useTranslation('common');

  const teams: PlayerWithPartnersMain[][] = getTeams().map(players => players.map((player, index) => ({ ...player, partnersMain: (players[index + 1] || players[0]).main })));

  return (
    <div className='partners-main-component'>
      {teams.map((team, index) => (
        <div className='team-container' key={`team-${index}`}>
          <span className='team-title medium-font-size'>{t('team')} {index + 1}</span>
          <div className='champion-selectors'>
            {team.map((player, index) => (
              <div className='player' key={`player-${index}`}>
                <div className='small-font-size'>{player.name} {t('will_play_with')} {player.partnersMain}</div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}

export default PartnersMain;
