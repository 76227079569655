import './Layout.css';
import Game from '../game/Game';
import InfoBlock from './components/info-block/InfoBlock';
import ConfigBlock from './components/config-block/ConfigBlock';
import { getStorageValue, setStorageValue } from '../../../services/StorageService';
import { LocalStorageData } from '../../../enums/StorageData';
import CustomToaster from '../../shared/custom-toaster/CustomToaster';
import TeamRandomizer from '../team-randomizer/TeamRandomizer';
import Summary from '../summary/Summary';

function Layout() {
  const showToastDefaultValue = getStorageValue(LocalStorageData.toast, true);

  return (
    <div className='layout-component'>
      <div className='header big-font-size'>Lol Roulette</div>
      <div className='top-right-block'>
        <ConfigBlock />
        <InfoBlock />
      </div>
      <div className='content'>
        <TeamRandomizer />
        <Game />
        <Summary />
      </div>
      <CustomToaster show={showToastDefaultValue} text='toast_content' onClose={() => setStorageValue(LocalStorageData.toast, false)} />
    </div>
  );
}

export default Layout;
