import './App.css';
import './styles/Fonts.css';
import './styles/Scrollbar.css';
import './styles/BootstrapComponents.css';
import Layout from './components/modules/layout/Layout';
import { SessionStorageDataEvents } from './enums/StorageData';
import { useEffect, useState } from 'react';
import { getSelectedLanguage } from './services/LanguagesService';
import { listenToStorageChanges } from './services/StorageService';

function App() {
  const [, setLang] = useState<string>();

  useEffect(() => {
    listenToStorageChanges(SessionStorageDataEvents.language, () => setLang(getSelectedLanguage()));
  }, []);

  return (
    <div className='app-component'>
      <Layout />
    </div>
  );
}

export default App;
