import './CustomToaster.css';
import { useState } from 'react';
import { Toast, ToastContainer } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

function CustomToaster(params: { text: string; show: boolean; onClose: () => void }) {
  const { t } = useTranslation('common');

  const [toastClassName, setToastClassName] = useState<'slide-in' | 'slide-out'>(params.show ? 'slide-in' : 'slide-out');
  const [showToast, setShowToast] = useState<boolean>(params.show);

  const hideToast = (): void => {
    setToastClassName('slide-out');
    params.onClose();
    setTimeout(() => setShowToast(false), 1000);
  };

  return (
    <div className='custom-toaster-component'>
      <ToastContainer position="bottom-center">
        <Toast
          show={showToast}
          autohide={false}
          animation={false}
          onClick={hideToast}
          className={`toast ${toastClassName}`}
        >
          <Toast.Body className='medium-font-size'>{t('toast_content')}</Toast.Body>
        </Toast>
      </ToastContainer>
    </div>
  );
}

export default CustomToaster;
