import './InfoBlock.css';
import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import CustomIcon from '../../../../shared/custom-icon/CustomIcon';

function InfoBlock() {
  const { t } = useTranslation('common');

  const [show, setShow] = useState<boolean>(false);

  return (
    <div className='info-block-component'>
      <CustomIcon type='info' onClick={() => setShow(true)} />
      <Modal
        id='info-modal'
        show={show}
        centered
        onHide={() => setShow(false)}
        backdrop={true}
      >
        <Modal.Body>
          <div className='big-font-size'>{t('about')}</div>
          <div className='medium-font-size' dangerouslySetInnerHTML={{ __html: t('copyright') }}></div>
          <div className='medium-font-size' dangerouslySetInnerHTML={{ __html: t('contact_me') }}></div>
          <div className='medium-font-size'>{t('thanks_for_using_lol_roulette')}</div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default InfoBlock;
