export enum SessionStorageData {
    language = 'language',
    teamsConfig = 'teamsConfig',
    wheelOptions = 'sessionWheelOptions'
}

export enum SessionStorageDataEvents {
    language = 'languageEvent'
}

export enum LocalStorageData {
    wheelOptions = 'localWheelOptions',
    summary = 'summary',
    toast = 'showToast'
}

export enum LocalStorageDataEvents {
    wheelOptions = 'wheelOptionsEvent',
    summary = 'summaryEvent'
}