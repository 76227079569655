import './CustomButton.css';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

function CustomButton(params: { text: string; type?: 'submit' | 'button'; disabled?: boolean; onClick?: () => void }) {
  const { t } = useTranslation('common');

  return (
    <Button
      className='custom-button-component'
      variant='secondary'
      type={params.type || 'button'}
      disabled={params.disabled}
      onClick={params.onClick}
    >
      {t(params.text)}
    </Button>
  );
}

export default CustomButton;
