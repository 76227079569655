import './TeamRandomizer.css';
import { useEffect, useState } from 'react';
import { Carousel, Form, InputGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { getTeams, getTeamsConfig, resetTeams, setTeams } from '../../../services/TeamsService';
import { hasMainChampionOptions } from '../../../services/WheelOptionsService';
import { listenToStorageChanges } from '../../../services/StorageService';
import { LocalStorageDataEvents } from '../../../enums/StorageData';
import { champions } from '../../../databases/champions';
import CustomButton from '../../shared/custom-button/CustomButton';

function TeamRandomizer() {
  const { t } = useTranslation('common');

  const _teamsConfig = getTeamsConfig();
  const [showMainChampionSelectors, setShowMainChampionSelectors] = useState<boolean>(hasMainChampionOptions());

  const [showForm, setShowForm] = useState<boolean>(!_teamsConfig.teams.length);
  const [playersNumber, setPlayersNumber] = useState<number>(_teamsConfig.players.length || 10);
  const [teamsNumber, setTeamsNumber] = useState<number>(_teamsConfig.teams.length || _teamsConfig.players.length || 2);
  const [players, setPlayers] = useState<{ name: string; main: string; }[]>(_teamsConfig.players.length ? _teamsConfig.players : new Array(playersNumber).fill({ name: '', main: '' }));

  const updatePlayersNumber = (value: string, limits: { min: number; max: number; }): void => {
    const _value = parseFloat(value);

    if (isNaN(_value) || _value < limits.min || _value > limits.max) { return; }

    const itemsDifference = _value - playersNumber;

    if (_value > playersNumber) {
      setPlayers([...players, ...new Array(itemsDifference).fill('')]);
    } else {
      const _players = [...players];
      _players.splice(itemsDifference);
      setPlayers(_players);
    }

    setPlayersNumber(_value);
  };

  const updateTeamsNumber = (value: string, limits: { min: number; max: number; }): void => {
    const _value = parseFloat(value);

    if (isNaN(_value) || _value < limits.min || _value > limits.max) { return; }

    setTeamsNumber(_value);
  };

  const updatePlayersValue = (playerIndex: number, property: 'name' | 'main', value: string): void => {
    const _players = players.map(player => ({ ...player }));
    _players[playerIndex][property] = value;
    setPlayers(_players);
  };

  const submitForm = (event: any): void => {
    event.preventDefault();
    event.stopPropagation();

    const validPlayers = players.filter(player => !!player.name);
    if (validPlayers.length >= teamsNumber) {
      setShowForm(false);
      setTeams(validPlayers, teamsNumber);
    }
  };

  useEffect(() => {
    if (showForm) {
      resetTeams();
    }
  }, [showForm]);

  useEffect(() => {
    listenToStorageChanges(LocalStorageDataEvents.wheelOptions, () => setShowMainChampionSelectors(hasMainChampionOptions()));
  }, []);

  return (
    <div className='team-randomizer-component custom-border'>
      <Carousel activeIndex={showForm ? 0 : 1} onSelect={() => setShowForm(!showForm)} controls={false} indicators={false} interval={null}>
        <Carousel.Item>
          <Form className='team-randomizer-form' onSubmit={submitForm}>
            <InputGroup className='main-form'>
              <InputGroup.Text>{t('players')}</InputGroup.Text>
              <Form.Control type='number' value={playersNumber} onChange={(e) => updatePlayersNumber(e.target.value, { min: 1, max: 16 })} />
              <InputGroup.Text>{t('teams')}</InputGroup.Text>
              <Form.Control type='number' isInvalid={teamsNumber > playersNumber} value={teamsNumber} onChange={(e) => updateTeamsNumber(e.target.value, { min: 1, max: 16 })} />
            </InputGroup>

            <div className='players-container'>
              {players.map((player, index) => (
                <Form.Group className='player-container' key={`player-${index}`}>
                  <Form.Control type='text' required placeholder={`${t('summoner')} ${index + 1}`} value={player.name} onChange={(e) => updatePlayersValue(index, 'name', e.target.value)} />
                  {showMainChampionSelectors && (
                    <Form.Select required className={`player-main-input${player.main ? '' : ' empty-select'}`} size='sm' value={player.main} onChange={(e) => updatePlayersValue(index, 'main', e.target.value)}>
                      <>
                        <option value={''} hidden>{t('main')}</option>
                        {champions.map((champion, index) => (<option key={`champion-${index}`}>{champion.name}</option>))}
                      </>
                    </Form.Select>
                  )}
                </Form.Group>
              ))}
            </div>

            <CustomButton text='generate_teams' type='submit' />
          </Form>
        </Carousel.Item>
        <Carousel.Item>
          <div className='results-container'>
            {getTeams().map((team, tIndex) => (
              <div className='team' key={`team-${tIndex}`}>
                <div>{t('team')} {tIndex + 1}</div>
                {team.map((player, pIndex) => (<div className='player medium-font-size' key={`player-${pIndex}`}>{player.name}</div>))}
              </div>
            ))}
          </div>
          <CustomButton text='go_back' onClick={() => setShowForm(true)} />
        </Carousel.Item>
      </Carousel>
    </div>
  );
}

export default TeamRandomizer;
