import { getTeams } from '../../../../services/TeamsService';
import './EnemyMains.css';
import { useTranslation } from 'react-i18next';

function EnemyMains() {
  const { t } = useTranslation('common');

  const mainsByTeam: string[][] = getTeams().map(team => team.map(player => player.main));
  const _teams = mainsByTeam.map((_, index) => mainsByTeam[index + 1] || mainsByTeam[0]);

  return (
    <div className='partners-main-component'>
      {_teams.map((team, index) => (
        <div className='team-container' key={`team-${index}`}>
          <span className='team-title medium-font-size'>{t('team_will_play_with_enemy_team_main_champions', { teamIndex: index + 1, enemyTeamIndex: ((index + 2) > _teams.length) ? 1 : index + 2})}:</span>
          <div className='champion-selectors'>
            {team.map((mains, index) =>
              <div className='champion small-font-size' key={`champion-${index}`}>{mains}</div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
}

export default EnemyMains;
