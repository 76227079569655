import { ChampionFilters } from '../enums/championFilters';
import { Aspects } from '../enums/championProperties';
import { Regions } from '../enums/regions';
import { Roles } from '../enums/roles';
import { WheelConditions } from '../enums/wheelConditions';
import { WheelFunctions } from '../enums/wheelFunctions';

export interface WheelOption {
    name: string;
    description?: string;
    action?: WheelFunctions;
    subOptionsType?: ChampionFilters;
    subOptions?: WheelSubOption[];
    condition?: WheelConditions;
    hide?: boolean;
    defaultHide?: boolean;
    isCustom?: boolean;
}

export interface WheelSubOption {
    name: string;
    value?: Roles | Regions | string;
    hide?: boolean;
    defaultHide?: boolean;
}

export const wheelOptions: WheelOption[] = [
    {
        name: 'by_role',
        subOptionsType: ChampionFilters.roles,
        subOptions: [
            { name: 'assassins', value: Roles.assassin },
            { name: 'fighters', value: Roles.fighter },
            { name: 'mages', value: Roles.mage },
            { name: 'marksman', value: Roles.marksman },
            { name: 'supports', value: Roles.support },
            { name: 'tanks', value: Roles.tank }
        ]
    },
    {
        name: 'pick_other_team',
        description: 'pick_other_team_description',
        action: WheelFunctions.pickEnemyTeamChampions,
        condition: WheelConditions.atLeastTwoTeams
    },
    {
        name: 'random_champion',
        description: 'random_champion_description',
        action: WheelFunctions.randomChampion
    },
    {
        name: 'by_region',
        subOptionsType: ChampionFilters.region,
        subOptions: [
            { name: 'bilgewater', value: Regions.bilgewater },
            { name: 'bandle', value: Regions.bandleCity },
            { name: 'demacia', value: Regions.demacia },
            { name: 'void', value: Regions.void },
            { name: 'freljord', value: Regions.freljord },
            { name: 'shadowIsles', value: Regions.shadowIsles },
            { name: 'ixtal', value: Regions.ixtal },
            { name: 'ionia', value: Regions.ionia },
            { name: 'noxus', value: Regions.noxus },
            { name: 'piltover', value: Regions.piltover },
            { name: 'shurima', value: Regions.shurima },
            { name: 'targon', value: Regions.targon },
            { name: 'zaun', value: Regions.zaun },
            { name: 'runaterra', value: Regions.runaterra }
        ]
    },
    {
        name: 'partners_main_champion',
        description: 'partners_main_champion_description',
        action: WheelFunctions.partnersMain,
        condition: WheelConditions.atLeastTwoPlayersPerTeam
    },
    {
        name: 'enemies_main_champion',
        description: 'enemies_main_champion_description',
        action: WheelFunctions.enemyMains,
        condition: WheelConditions.atLeastTwoTeams
    },
    {
        name: 'by_appearance',
        subOptionsType: ChampionFilters.aspect,
        subOptions: [
            { name: 'with_hat', value: Aspects.withHat },
            { name: 'bald', value: Aspects.bald },
            { name: 'with_moustach', value: Aspects.withMoustach },
            { name: 'with_sword', value: Aspects.withSword },
            { name: 'with_fire_weapon', value: Aspects.withFireWeapon },
            { name: 'furry', value: Aspects.furry },
            { name: 'with_mount', value: Aspects.withMount },
            { name: 'chonky', value: Aspects.chonky },
            { name: 'humanoid_women', value: Aspects.humanoidWomen },
            { name: 'cant_talk', value: Aspects.cantTalk }
        ]
    },
    {
        name: 'dynamic_duo',
        description: 'dynamic_duo_description',
        subOptions: [
            { name: 'Soraka / Milio' },
            { name: 'Teemo / Shaco' },
            { name: 'Annie / Ivern' },
            { name: 'Taric / Leona' },
            { name: 'Heimerdinger / Zyra' },
            { name: 'Morgana / Lux' },
            { name: 'Ezreal / Xerath' },
            { name: 'Zac / Anivia' }
        ],
        condition: WheelConditions.twoPlayersPerTeam
    },
    {
        name: 'by_initial_letter',
        description: 'by_initial_letter_description',
        action: WheelFunctions.randomInitial
    }
];