import './Game.css';
import { useEffect, useState } from 'react';
import { WheelOption, WheelSubOption } from '../../../databases/wheelOptions';
import { playAudio, stopAudio, useAudio } from '../../../services/AudioService';
import CustomModal from '../../shared/custom-modal/CustomModal';
import { canResetWheelOptions, getSecondaryWheelOptionOptions, getFilteredSecondaryWheelOptions, getFilteredWheelOptions, resetWheelOptions } from '../../../services/WheelOptionsService';
import { resetSummaryContent, setSummaryContent } from '../../../services/SummaryService';
import { LocalStorageDataEvents, SessionStorageDataEvents } from '../../../enums/StorageData';
import { getTeams } from '../../../services/TeamsService';
import { useTranslation } from 'react-i18next';
import { listenToStorageChanges } from '../../../services/StorageService';
import LuckyWheel from './components/lucky-wheel/LuckyWheel';
import CustomButton from '../../shared/custom-button/CustomButton';

function Game() {
  const { t } = useTranslation('common');

  useAudio();

  const [isWheelSpinning, setIsWheelSpinning] = useState<boolean>(false);

  // Main wheel
  const [mainWheelOptions, setMainWheelOptions] = useState<WheelOption[]>([]);
  const [initMainWheelOptions, setInitMainWheelOptions] = useState<boolean>(true);

  // Secondary wheel
  const [showSecondaryWheel, setShowSecondaryWheel] = useState<boolean>(false);
  const [secondaryWheelOptions, setSecondaryWheelOptions] = useState<WheelSubOption[]>([]);

  // Modal
  const [showTeamsModal, setShowTeamsModal] = useState<boolean>(false);

  const checkMainWheelEnabled = (): boolean => {
    if (!!getTeams().length) {
      return true;
    }

    setShowTeamsModal(true);
    return false;
  };

  const onSpinMainWheel = (): void => {
    resetSummaryContent();
    playAudio();
    setIsWheelSpinning(true);
  };

  const onMainWheelSelectOption = (selectedOption: WheelOption): void => {
    setIsWheelSpinning(false);

    if (selectedOption.subOptions) {
      setSecondaryWheelOptions(getFilteredSecondaryWheelOptions(selectedOption.name));
      setTimeout(() => setShowSecondaryWheel(true), 1000);
    } else {
      stopAudio();
      setSummaryContent({ title: selectedOption.name, description: selectedOption.description, action: selectedOption.action });
    }
  };

  const onSecondaryWheelSelectOption = (selectedOption: WheelSubOption): void => {
    const mainWheelOption = mainWheelOptions.find(mainWheelOption => mainWheelOption.subOptions?.find(suboption => suboption.name === selectedOption.name));
    if (mainWheelOption) {
      setTimeout(() => {
        closeSecondaryWheelModal();
        setSummaryContent({ title: mainWheelOption.name, subtitle: selectedOption.name, description: mainWheelOption.description, options: getSecondaryWheelOptionOptions(mainWheelOption, selectedOption) });
      }, 1000);
    }
  };

  const closeSecondaryWheelModal = (): void => {
    stopAudio();
    setIsWheelSpinning(false);
    setShowSecondaryWheel(false);
  };

  if (initMainWheelOptions) {
    setMainWheelOptions(getFilteredWheelOptions());
    setInitMainWheelOptions(false);
  }

  useEffect(() => {
    listenToStorageChanges(LocalStorageDataEvents.wheelOptions, () => setMainWheelOptions(getFilteredWheelOptions()));
    listenToStorageChanges(SessionStorageDataEvents.language, () => setMainWheelOptions(getFilteredWheelOptions()));
  }, []);

  return (
    <div className='game-component'>
      {!!mainWheelOptions.length && (
        <>
          <LuckyWheel
            options={mainWheelOptions}
            onSelectOption={(result) => onMainWheelSelectOption(result)}
            startSpin={onSpinMainWheel}
            checkEnabled={checkMainWheelEnabled}
          />
          {canResetWheelOptions() && (
            <div className='reset-wheel-options-button'>
              <CustomButton text='reset' onClick={resetWheelOptions} disabled={isWheelSpinning} />
            </div>
          )}
        </>
      )}

      {!!secondaryWheelOptions.length && (
        <CustomModal id='secondary-wheel-modal' show={showSecondaryWheel} backdrop={false} onHideModal={() => setShowSecondaryWheel(false)}>
          <LuckyWheel
            options={secondaryWheelOptions}
            onSelectOption={(result) => onSecondaryWheelSelectOption(result)}
            startSpin={() => setIsWheelSpinning(true)}
          />
          <CustomButton text='go_back' onClick={closeSecondaryWheelModal} disabled={isWheelSpinning} />
        </CustomModal>
      )}

      {!mainWheelOptions?.length && (
        <>
          <div className='default-message'>{t('empty_roulette')}</div>
          <CustomButton text='reset' onClick={resetWheelOptions} />
        </>
      )}

      {showTeamsModal && (
        <CustomModal id='required-teams-modal' show={showTeamsModal} backdrop={false} onHideModal={() => setShowTeamsModal(false)}>
          <div>{t('missing_teams')}</div>
          <CustomButton text='go_back' onClick={() => setShowTeamsModal(false)} />
        </CustomModal>
      )}
    </div>
  );
}

export default Game;
